import { useSelector } from "react-redux";
import {
  selectEmail,
  selectFullName,
  selectPhone,
  selectPostalCode,
  selectFeedbackMessage,
  selectSalesmanName,  // Import salesmanName selector
} from "../../../store/reducers/formInfoSlice";
import { selectSelectedId as selectSelectedModelId } from "../../../store/reducers/modelChoiceSlice";
import { selectShedOptionsState } from "../../../store/reducers/shedOptionsSlice";
import { selectColorChoices } from "../../../store/reducers/colorChoiceSlice";
import { translateColorCode } from "../PDFGenerationUtils/choiceTranslationUtils/translateColorCode";
import { useFormattedDimensions } from "./dimensionsUtils";
import useGenerateOrderNumber from "../PDFGenerationUtils/choiceTranslationUtils/generateOrderNumber";
import getModelTypeText from "../PDFGenerationUtils/choiceTranslationUtils/modelTexts";
import {
  translateIsGarageChecked,
  translateIsRoomChecked,
  translateSelectedGarageOption,
  translateSelectedRoomOption,
  translateIsShedPositionChecked,
  translateisPhotovoltaicsReinforcementChecked,
  translateShedPosition,
  translateIsSnowZoneChecked,
  translateIsMontageChecked,
  translateIsGrillTypeChecked,
  translateIsWallTypeChecked,
  translateIsGutterTypeChecked,
} from "../PDFGenerationUtils/choiceTranslationUtils/translateShedOptions";
import { selectSelectedId as isSelectedPanelColorId } from "../../../store/reducers/panelColorChoiceSlice";
import { translatePanelColorId } from "../PDFGenerationUtils/choiceTranslationUtils/panelColorTranslations";
import { selectSelectedId as selectSelectedPanelId } from "../../../store/reducers/panelChoiceSlice";
import { translatePanelId } from "../PDFGenerationUtils/choiceTranslationUtils/panelIdTranslations";
import { selectSelectedSquares } from "../../../store/reducers/panelPlacementSlice";
import { calculateSelectedSquares } from "../SummaryFormComponent/squaresCalculation";
import { selectDimensionsBySelectedId } from "../../../store/reducers/dimensionsSlice";

const useSetPDFParams = () => {
  // User Info
  const email = useSelector(selectEmail);
  const fullName = useSelector(selectFullName);
  const phone = useSelector(selectPhone);
  const postalCode = useSelector(selectPostalCode);
  const feedbackMessage = useSelector(selectFeedbackMessage);
  const salesmanName = useSelector(selectSalesmanName);  // Extract salesman name

  // Model and Order Info
  const selectedModelId = useSelector(selectSelectedModelId);
  const generateOrderNumber = useGenerateOrderNumber();
  const orderNumber = generateOrderNumber();

  // Model Type Text - KEEPING ORIGINAL LOGIC
  let modelTypeText = getModelTypeText(selectedModelId);

  // Dimensions and Formatted Dimensions
  const dimensions = useSelector((state) =>
    selectDimensionsBySelectedId(state, selectedModelId)
  );
  const {
    formattedWidth,
    formattedLength,
    formattedHeight,
    formattedRoofDimension,
    pgDimensionInformation,
  } = useFormattedDimensions();

  // Shed Options
  const shedOptions = useSelector(selectShedOptionsState);
  const garageChecked = translateIsGarageChecked(shedOptions.isGarageChecked);
  const garageOption = translateSelectedGarageOption(
    shedOptions.selectedGarageOption
  );
  const shedChecked = translateIsRoomChecked(shedOptions.isRoomChecked);
  const shedOption = translateSelectedRoomOption(
    shedOptions.selectedRoomOption
  );
  const shedPositionChecked = translateIsShedPositionChecked(
    shedOptions.isShedPositionChecked
  );
  const shedPosition = translateShedPosition(shedOptions.shedPosition);
  const montageChecked = translateIsMontageChecked(shedOptions.isMontageChecked);
  const snowZoneChecked = translateIsSnowZoneChecked(shedOptions.isSnowZoneChecked);
  const photovoltaicsReinforcementChecked =
    translateisPhotovoltaicsReinforcementChecked(
      shedOptions.isPhotovoltaicsReinforcementChecked
    );
  const maskownicaPremiumChecked = translateIsGrillTypeChecked(
    shedOptions.isGrilleTypeChecked
  );
  const wallTypeChecked = translateIsWallTypeChecked(
    shedOptions.isWallTypeChecked,
    shedOptions.isGarageChecked,
    shedOptions.isRoomChecked
  );
  const rynnaChecked = translateIsGutterTypeChecked(shedOptions.isGutterTypeChecked);

  // Color Choices
  const colorChoices = useSelector(selectColorChoices);
  const poleColorName = translateColorCode(colorChoices.poleColor);
  const roofColorName = translateColorCode(colorChoices.roofColor);
  const facadeColorName = translateColorCode(colorChoices.facadeColor);

  // Panel Color and ID
  const selectedPanelColorId = useSelector(isSelectedPanelColorId);
  const panelColorName = translatePanelColorId(selectedPanelColorId);  // Fetching the panel color name

  const selectedPanelId = useSelector(selectSelectedPanelId);
  const translatedPanelId = translatePanelId(selectedPanelId);  // Fetching the translated panel ID

  // Selected Squares Calculation
  const selectedSquares = useSelector(selectSelectedSquares);
  const countOfSelectedSquares = calculateSelectedSquares(
    selectedSquares,
    selectedModelId,
    dimensions,
    shedOptions
  );

  // Final PDF Data Object
  const pdfData = {
    // Basic Info
    fullName,
    userEmail: email,
    phone,
    postalCode,
    salesmanName,  // Include salesman name here

    // Model and Order Info
    modelType: modelTypeText,  // Original logic preserved
    orderNumber,

    // Dimensions
    width: formattedWidth,
    length: formattedLength,
    height: formattedHeight,
    roofDimension: formattedRoofDimension,

    // Shed Options
    garageChecked,
    garageOption,
    shedChecked,
    shedOption,
    snowZoneChecked,
    shedPositionChecked,
    shedPosition,
    montageChecked,
    photovoltaicsReinforcementChecked,
    maskownicaPremiumChecked,
    wallTypeChecked,
    rynnaChecked,

    // Color Information
    poleColorName,
    roofColorName,
    facadeColorName,
    panelColorName,   // Added panel color from panelColorChoiceSlice

    // Panel ID and Squares
    translatedPanelId, // Added panel ID translation
    countOfSelectedSquares, // Added count of selected squares

    // Feedback
    feedbackMessage,

    // Additional Information
    pgDimensionInformation,
  };

  return pdfData;
};

export default useSetPDFParams;
