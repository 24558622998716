import { createSlice } from '@reduxjs/toolkit';

export const formInfoSlice = createSlice({
  name: 'formInfo',
  initialState: {
    email: '',
    fullName: '',
    phone: '',
    postalCode: '',
    feedbackMessage: '',
    salesmanName: '', // Add salesman's name here
  },
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setPostalCode: (state, action) => {
      state.postalCode = action.payload;
    },
    setFeedbackMessage: (state, action) => {
      state.feedbackMessage = action.payload;
    },
    setSalesmanName: (state, action) => { 
      state.salesmanName = action.payload;
    },
  },
});

export const { setEmail, setFullName, setPhone, setPostalCode, setFeedbackMessage, setSalesmanName } = formInfoSlice.actions;

export const selectEmail = (state) => state.formInfo.email;
export const selectFullName = (state) => state.formInfo.fullName;
export const selectPhone = (state) => state.formInfo.phone;
export const selectPostalCode = (state) => state.formInfo.postalCode;
export const selectFeedbackMessage = (state) => state.formInfo.feedbackMessage;
export const selectSalesmanName = (state) => state.formInfo.salesmanName; 

export default formInfoSlice.reducer;
