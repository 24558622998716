import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux"; // Import useDispatch from react-redux
import { setSalesmanName } from "../../../store/reducers/formInfoSlice";

const parseDateTime = (dateString, timeString) => {
  const [day, month, year] = dateString.split(".");
  const [hours, minutes] = timeString.split(":");
  return new Date(year, month - 1, day, hours, minutes);
};

const getEmailTemplateId = (harmonogram) => {
  const currentDate = new Date();
  //console.log("Current date and time:", currentDate);

  // Filter out periods that have already ended
  const upcomingPeriods = harmonogram.filter((period) => {
    const endDateTime = parseDateTime(period["Data do"], period["Godzina do"]);
    return endDateTime >= currentDate;
  });

  for (const period of upcomingPeriods) {
    const startDateTime = parseDateTime(
      period["Data od"],
      period["Godzina od"]
    );
    const endDateTime = parseDateTime(period["Data do"], period["Godzina do"]);

    //console.log(
    //  `Checking period: ${period["Data od"]} ${period["Godzina od"]} to ${period["Data do"]} ${period["Godzina do"]}`
    //);
    //console.log("Start date and time:", startDateTime);
    //console.log("End date and time:", endDateTime);

    if (currentDate >= startDateTime && currentDate <= endDateTime) {
      //console.log(`Match found! Using template: ${period.zmienna}`);
      // Return the environment variable name (e.g., REACT_APP_EMAILJS_TEMPLATE_ID_MONIKA)
      return period.zmienna;
    }
  }

  //console.log("No matching period found, using default template");
  return "REACT_APP_EMAILJS_TEMPLATE_ID_USER";
};
const useEmailTemplateSelector = () => {
  const [templateId, setTemplateId] = useState(null);
  const [harmonogram, setHarmonogram] = useState([]);
  const dispatch = useDispatch(); // Create dispatch instance

  // Map of environment variable names to salesman names
  const salesmanMapping = {
    REACT_APP_EMAILJS_TEMPLATE_ID_USER: "Brak",
    REACT_APP_EMAILJS_TEMPLATE_ID_ROBERT: "Robert Mistewicz",
    REACT_APP_EMAILJS_TEMPLATE_ID_TEODOR: "Teodor Cimoszewicz",
    REACT_APP_EMAILJS_TEMPLATE_ID_MONIKA: "Monika Kowalczyk",
    REACT_APP_EMAILJS_TEMPLATE_ID_ANNA: "Anna Sykta",
    REACT_APP_EMAILJS_TEMPLATE_ID_MACIEJ: "Maciej Gajewski",
  };

  useEffect(() => {
    const fetchHarmonogram = async () => {
      try {
        const response = await axios.get(
          "https://marciniakogrodzenia.pl/wp-content/uploads/2024/09/harmonogram.json"
        );
        setHarmonogram(response.data);
        const initialTemplateId = getEmailTemplateId(response.data);

        // Now get the actual secret template ID from environment variables
        const actualTemplateId = process.env[initialTemplateId];
        setTemplateId(actualTemplateId);

        // Set the salesman name based on the environment variable name
        const initialSalesmanName = salesmanMapping[initialTemplateId] || "Brak";
        dispatch(setSalesmanName(initialSalesmanName));
       // console.log(
        //  "Fetched harmonogram and initialized templateId:",
        //  actualTemplateId,
        //  "with salesman:",
        //  initialSalesmanName
        //);
      } catch (error) {
        //console.error("Error fetching harmonogram:", error);
      }
    };

    fetchHarmonogram();
  }, [dispatch]);

  useEffect(() => {
    if (harmonogram.length === 0) {
      return;
    }

    //console.log("Setting up interval for templateId updates");

    const updateTemplateId = () => {
      //console.log("Checking for template update");
      const newTemplateId = getEmailTemplateId(harmonogram);
      const actualTemplateId = process.env[newTemplateId];

      if (actualTemplateId !== templateId) {
        //console.log(`Template changed from ${templateId} to ${actualTemplateId}`);
        setTemplateId(actualTemplateId);
      } else {
        //console.log("No template change needed");
      }

      // Update the salesman name based on the environment variable name
      const salesmanName = salesmanMapping[newTemplateId] || "Brak";
      //console.log(`Setting salesman name to: ${salesmanName}`);
      dispatch(setSalesmanName(salesmanName));
    };

    // Update template ID every minute
    const intervalId = setInterval(updateTemplateId, 60000);

    // Clean up interval on unmount
    return () => {
      //console.log("Cleaning up interval");
      clearInterval(intervalId);
    };
  }, [templateId, harmonogram, dispatch]);

  //console.log("Returning templateId:", templateId);
  return templateId;
};

export default useEmailTemplateSelector;
