import React from "react";
import { View, Text, StyleSheet, Font } from "@react-pdf/renderer";

// Register the Roboto font family
Font.register({
  family: "Roboto",
  fonts: [
    { src: "/fonts/Roboto-Regular.ttf", fontWeight: "normal" },
    { src: "/fonts/Roboto-Bold.ttf", fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  introContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "90%",
    marginBottom: 5,
    marginLeft: 29.5
  },
  thanksContainer: {
    marginTop: 40,
  },
  offerContainer: {
    marginTop: 5,
    marginBottom: 10,
  },
  modelTypeContainer: {
    marginTop: 5,
    marginBottom: 5,
  },
  handlowiecContainer: {
    marginTop: 5,
    marginBottom: 5,
  },
  introText: {
    fontFamily: "Roboto",
    fontSize: 9,
    fontWeight: "bold",
    color: "#000000",
  },
  additionalText: {
    fontFamily: "Roboto",
    fontSize: 9,
    fontWeight: "normal",
    color: "#000000",
  },
});

const IntroSection = ({ data }) => {
  // Define the list of valid salespeople
  const validSalespeople = [
    "Robert Mistewicz",
    "Teodor Cimoszewicz",
    "Monika Kowalczyk",
    "Anna Sykta",
    "Maciej Gajewski",
  ];

  return (
    <View style={styles.introContainer}>
      {/* Existing content */}
      <View style={styles.thanksContainer}>
        <Text style={styles.introText}>
          Dziękujemy za zaufanie i wybór naszej firmy.
        </Text>
      </View>

      <View style={styles.offerContainer}>
        <Text style={styles.additionalText}>
          Poniżej znajduje się szczegółowy opis spersonalizowanej wiaty samochodowej. Prosimy spodziewać się kontaktu z naszej
          strony w najbliższym czasie lub, w przypadku pilnych spraw, o bezpośredni kontakt. Dla usprawnienia procesu obsługi,
          uprzejmie prosimy o podanie numeru oferty.
        </Text>
      </View>

      <Text style={styles.introText}>Oferta nr {data.orderNumber} dla:</Text>
      <Text style={styles.additionalText}>{data.phone}</Text>
      <Text style={styles.additionalText}>{data.userEmail}</Text>

      {/* New section for Nazwa Produktu/Typ */}
      <View style={styles.modelTypeContainer}>
        <Text style={styles.introText}>Nazwa Produktu/Typ:</Text>
        <Text style={styles.additionalText}>Wiata Samochodowa / {data.modelType}</Text>
      </View>

      {/* Conditionally render the Osobisty opiekun klienta section */}
      {data.salesmanName !== "Brak" && validSalespeople.includes(data.salesmanName) && (
        <View style={styles.handlowiecContainer}>
          <Text style={styles.introText}>Osobisty opiekun klienta:</Text>
          <Text style={styles.additionalText}>{data.salesmanName}</Text>
        </View>
      )}

      {/* New section for Szczegóły konfiguracji */}
      <Text style={styles.introText}>Szczegóły konfiguracji:</Text>
    </View>
  );
};

export default IntroSection;
